.about {
  display: flex;
  padding: 100px 100px;
  justify-content: space-between;
}
.about-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 650px;
}
.about-content h1 {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #644a3e;
}
.about-content .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-content .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 3px;
  color: #1b1a1a;
}
.about-content button {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 151px;
  height: 44px;
  background: #0e703c;
  border: 1px solid #0e703c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .about {
    display: flex;
    padding: 50px 20px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .about-image {
    display: none;
  }

  .about-content button {
    margin-top: 20px;
  }
  .about-content h1 {
    font-size: 30px;
  }
}
