.contactus-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 100px;
}
.contactus-section .logo img {
  width: 332px;
}
.contactus-section .location {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: left;
}
.contactus-section .location span {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #1b1a1a;
}
.contactus-section .location .loc {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 270px;
}
.contactus-section .location .loc p {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #000000;
}
/* /////////// */
.contact-form .head {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.contact-form .head h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;
  letter-spacing: 3px;
  text-transform: capitalize;

  color: #6b5310;
}
.contact-form .head h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #000000;
}
.contact-form form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 30px 0px;
}
.contact-form form div {
  display: flex;
  flex-direction: column;
}
.contact-form form input {
  width: 600px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}
.contact-form form label {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.contact-form form textarea {
  width: 600px;
  height: 148px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}
.contact-form form input[type="text"] {
  font-size: 20px;
  padding-left: 20px;
}
.contact-form form input[type="email"] {
  font-size: 20px;
  padding-left: 20px;
}
.contact-form form input[type="number"] {
  font-size: 20px;
  padding-left: 20px;
}
.contact-form form textarea {
  font-size: 20px;
  padding-left: 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contact-form form button {
  margin-top: 50px;
  width: 296.78px;
  height: 53.34px;
  background: #0e703c;
  border-radius: 8.72877px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .contactus-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px 20px;
  }
  .contactus-section .location span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #1b1a1a;
  }
  .contactus-section .location .loc p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
  .contactus-section .logo img {
    width: 232px;
  }
  .contactus-section .location {
    gap: 5px;
  }

  .contact-form .head {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .contact-form .head h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 2px;
    text-transform: capitalize;

    color: #6b5310;
  }
  .contact-form .head h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: capitalize;

    color: #000000;
  }
  .contact-form form input {
    width: 312px;
    height: 33.28px;
    left: 24px;
    top: 989.32px;

    background: #ffffff;
    border: 0.52px solid #000000;
    border-radius: 4.16px;
  }
  .contact-form form label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  .contact-form form textarea {
    width: 312px;
    height: 76.96px;
    background: #ffffff;
    border: 0.52px solid #000000;
    border-radius: 4.16px;
  }

  .contact-form form input[type="text"] {
    font-size: 14px;
    padding-left: 10px;
  }
  .contact-form form input[type="email"] {
    font-size: 14px;
    padding-left: 10px;
  }
  .contact-form form input[type="number"] {
    font-size: 14px;
    padding-left: 10px;
  }
  .contact-form form textarea {
    font-size: 14px;
    padding-left: 10px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .contact-form form button {
    margin-top: 20px;
    width: 154.32px;
    height: 27.74px;
    background: #0e703c;
    border-radius: 4.53896px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
  }
}
