.banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-data {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}
.banner-data .images {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.banner-data h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
.banner-data button {
  width: 192.16px;
  height: 52.98px;
  background: #0e703c;
  border-radius: 9.45px;
  font-style: normal;
  font-weight: 700;
  font-size: 19.9341px;
  line-height: 29px;
  color: #ffffff;
  flex-grow: 0;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media only screen and (max-width: 1024px) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-data {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }
  .banner-data .images {
    display: flex;
    flex-direction: column;
    width: 250px;
  }
  .banner-data h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height, or 162% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffffff;
  }
  .banner-data button {
    width: 15 0.16px;
    height: 40.98px;
    background: #0e703c;
    border-radius: 9.45px;
    font-style: normal;
    font-weight: 700;
    font-size: 14.9341px;
    line-height: 29px;
    color: #ffffff;
    flex-grow: 0;
    border: none;
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
