.features {
  background: url("/public/assets/images/ourFeatures.svg"), #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
}
@media only screen and (max-width: 1024px) {
  .features {
    background: url("/public/assets/images/ourFeatures.svg"), #fafafa;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
  }
}
