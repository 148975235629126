.getintouch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 100px 150px;
  background: #f9fafb;
  border-radius: 16px;
  padding: 50px;
}
.getintouch h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #101828;
}
.getintouch p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;
}
.getintouch button {
  width: 130px;
  height: 44px;

  background: #0e703c;
  border: 1px solid #0e703c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .getintouch {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin: 50px 20px;
    background: #f9fafb;
    border-radius: 16px;
  }
  .getintouch h1 {
    font-size: 22px;
  }
  .getintouch p {
    font-size: 15px;
  }
}
