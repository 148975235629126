header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 104px;
  padding: 0 3rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  /* position: sticky;
  top: 0; */
}

header a img {
  width: 150px;
}
.active {
  color: #19e87a;
}
nav a {
  margin: 0 0.8rem;
  color: white;
  text-decoration: none;

  letter-spacing: 0.04em;
  text-transform: capitalize;

  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  cursor: pointer;
}

nav a:hover {
  color: #19e87a;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header {
    height: 80px;
    /* position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1; */
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    position: relative;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #644a3e;
    transition: 0.5s;
    transform: translateX(-100vh);
    z-index: 1;
  }

  header a img {
    width: 170px;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #ffffff;
  }

  nav a {
    font-size: 1rem;
    color: #ffffff;
  }
  nav a:hover {
    font-size: 1.7rem;
    color: #0e703c;
  }
}
