.products {
  background: url("/public/assets/images/productBanner.svg"), #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
}
.products-table {
  background: url("/public/assets/images/products.svg "), #fafafa;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
}
@media only screen and (max-width: 1024px) {
  .products {
    background: url("/public/assets/images/productBanner.svg"), #fafafa;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
  }
  .products-table {
    background: url("/public/assets/images/products.svg "), #fafafa;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
  }
}
