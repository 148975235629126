.contact-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-banner-data {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  width: 450px;
}

.contact-banner-data p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}
.contact-banner-data h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 62px;
  color: #ffffff;
}
.contact-banner-data span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .contact-banner-data {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 200px;
  }
  .contact-banner-data p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }
  .contact-banner-data h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 37px;
    line-height: 40px;
    /* or 168% */

    color: #ffffff;
  }
  .contact-banner-data span {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #ffffff;
    text-align: left;
  }
}
