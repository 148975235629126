.wood-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 80px 0;
}
.wood-cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 405.67px;
  height: 380.01px;
  background: #ffffff;
  border: 0.950036px solid #000000;
  box-shadow: 0px 3.80014px 3.80014px rgba(9, 6, 6, 0.63);
  border-radius: 7.60029px;
  gap: 20px;
}
.wood-cards .card .card-content h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24.0007px;
  line-height: 27px;
  text-align: center;
  color: #000000;
}
.card-content .card-details p {
  font-style: normal;
  font-weight: 500;
  font-size: 13.3005px;
  line-height: 27px;
  color: #000000;
}
.card-content button {
  width: 251.76px;
  height: 41.8px;
  background: #0e703c;
  border-radius: 3.80014px;
  font-style: normal;
  font-weight: 500;
  font-size: 15.2006px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  border: none;
  color: #ffffff;
}
.card-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.wood-card-head {
  margin: 50px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  text-align: center;
  color: #6b5310;
}
@media only screen and (max-width: 1024px) {
  .wood-cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 30px 0px;
    gap: 30px;
  }
  .wood-cards .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 305.67px;
    height: 360.01px;
    background: #ffffff;
    border: 0.950036px solid #000000;
    box-shadow: 0px 3.80014px 3.80014px rgba(9, 6, 6, 0.63);
    border-radius: 7.60029px;
    gap: 20px;
  }
  .wood-cards .card .card-content h1 {
    font-size: 20.0007px;
  }
  .card-content .card-details p {
    font-style: normal;
    font-weight: 500;
    font-size: 11.3005px;
    line-height: 27px;
    color: #000000;
  }
  .wood-card-head {
    font-size: 10.0007px;
  }
}
