.footer {
  padding: 50px;
  background: #f8fafc;
  display: flex;
  flex-direction: column;
}
.footer-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 100px;
}
.footer-items div span:first-child {
  color: #94a3b8;
  font-weight: 600;
  font-size: 13.1605px;
  line-height: 18px;
  letter-spacing: 1.01235px;
}
.footer-items div span {
  font-style: normal;
  font-weight: 500;
  font-size: 16.1975px;
  line-height: 24px;
  cursor: pointer;
  color: #090914;
}
.footer-items .location {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 270px;
}

.footer-items .location .social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
}
.footer-items .help {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.footer-items .newsletter {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.footer-items .company {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.footer-items .newsletter form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer-items .newsletter form button {
  width: 296.78px;
  height: 53.34px;
  left: 1102.22px;
  background: #0e703c;
  border-radius: 8.72877px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
}
.footer-items .newsletter form input {
  box-sizing: border-box;
  width: 296.78px;
  height: 53.34px;
  background: #ffffff;
  border: 0.969864px solid #e4e4e7;
  border-radius: 9.69864px;
}
.footer-items .newsletter form input::placeholder {
  padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15.5178px;
  line-height: 25px;
  color: #a1a1aa;
}
.footer-items .newsletter form input[type="text"] {
  padding-left: 10px;
  font-size: 18px;
  text-align: left;
}
.footer-copyright p {
  padding-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 13.23px;
  line-height: 21px;
  text-align: center;
  color: #090914;
}
@media only screen and (max-width: 1024px) {
  .footer-items .newsletter {
    display: none;
  }
  .footer-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 20px 0px;
  }
  .footer-items div span:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height, or 130% */

    letter-spacing: 1.01235px;
    text-transform: uppercase;

    color: #d64627;
  }
}
