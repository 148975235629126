.process-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.process-header h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;

  color: #000000;
}
.process-header h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #663d1c;
}
.process-details {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}
.process-details .left .img {
  position: relative;
  text-align: center;
}
.process-details .left .img span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;

  color: #ffffff;
}
.process-details .right .img {
  position: relative;
  text-align: center;
}
.process-details .right .img span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #ffffff;
}
.process-details .right h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  color: #6b5310;
}
.process-details .right p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 3px;

  color: #1b1a1a;
}
.process-details .left h5 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #6b5310;
}
.process-details .left p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 3px;
  color: #1b1a1a;
}
.process-details .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.process-details .left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.process-section .image {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.process-section .image img {
  width: 500px;
}
.result {
  display: flex;
  padding: 50px 100px;
  justify-content: space-between;
}
.result-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
}
.result-content h1 {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #644a3e;
}
.result-content .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.result-content .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  display: flex;
  align-items: center;
  letter-spacing: 3px;

  color: #1b1a1a;
}
.result-content button {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 151px;
  height: 44px;

  background: #0e703c;
  border: 1px solid #0e703c;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.result-content .image {
  position: relative;
  text-align: center;
}
.result-content .image img {
  width: 80px;
}
.result-content .image span {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
  /* identical to box height, or 83% */

  display: flex;
  align-items: center;
  letter-spacing: 3px;
  text-transform: capitalize;

  color: #ffffff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.result-image img {
  width: 580px;
}
.process-advantages {
  margin: 20px 100px;
  background: url("/public/assets/images/Rectangle 6.png");
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.process-advantages .head {
  margin-left: 10px;
}
.process-advantages .head h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 38.1799px;
  line-height: 54px;
  text-align: center;
  -webkit-text-stroke: 2px white;
  color: transparent;
}
.process-advantages .head h2 {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 34.709px;
  line-height: 54px;

  color: #ffffff;
}
@media only screen and (max-width: 1024px) {
  .process-header {
    display: none;
  }

  .process-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 20px;
    gap: 20px;
  }
  .process-details .left .img {
    position: relative;
    text-align: center;
  }
  .process-details .left .img img {
    width: 50px;
  }
  .process-details .right .img img {
    width: 50px;
  }
  .process-details .left .img span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: capitalize;

    color: #ffffff;
  }
  .process-details .right .img {
    position: relative;
    text-align: center;
  }
  .process-details .right .img span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: capitalize;
    color: #ffffff;
  }
  .process-details .right h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height, or 136% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;
    text-transform: capitalize;

    color: #6b5310;
  }
  .process-details .right p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 168% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;

    color: #1b1a1a;
  }
  .process-details .left h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 3px;
    text-transform: capitalize;

    color: #6b5310;
  }
  .process-details .left p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 168% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;

    color: #1b1a1a;
  }
  .process-details .right {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .process-details .left {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .process-section .image img {
    width: 250px;
  }
  .result {
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .result-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 610px;
  }
  .result-content h1 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: capitalize;
    color: #6b5310;
  }
  .result-content .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .result-content .content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 168% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;

    color: #1b1a1a;
  }
  .result-content button {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;

    width: 151px;
    height: 44px;

    background: #0e703c;
    border: 1px solid #0e703c;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .result-content .image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .result-content .image img {
    width: 50px;
  }
  .result-content .image span {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height, or 83% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;
    text-transform: capitalize;

    color: #ffffff;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .result-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .result-image img {
    width: 350px;
  }
}
.adv-sec {
  background: url("/public/assets/images/group1.jpg"), #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;

  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.adv-sec .header {
  padding: 40px 30px;
}
.adv-sec .header h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 54px;
  -webkit-text-stroke: 2px white;
  color: transparent;
}
.adv-sec .header h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}
