.features-section {
  padding: 20px 200px;
}
.features-section h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 30px;
  /* identical to box height, or 94% */

  letter-spacing: 3px;
  text-transform: capitalize;
  text-align: center;
  color: #6b5310;
  margin: 60px 0;
}
