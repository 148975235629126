.about-us {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.about-us h1 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  color: #644a3e;
}
.about-us h1 span {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 30px;
  color: #b24e20;
}
.about-us .content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about-us .content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 3px;
  color: #333333;
}
.about-us-details {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.about-us-details h1 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  /* identical to box height, or 94% */

  letter-spacing: 3px;
  text-transform: capitalize;

  color: #644a3e;
}
.about-us-details .content {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
.about-us-details .content .data {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  /* max-width: 500px; */
}
.about-us-details .content .data h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 3px;
  text-transform: capitalize;
  color: #6b5310;

  text-align: left;
}
.about-us-details .content .data p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 3px;
  color: #1b1a1a;
}
@media only screen and (max-width: 1024px) {
  .about-us {
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .about-us .content {
    padding: 10px 10px;
  }
  .about-us .content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 3px;
    color: #333333;
  }
  .about-us-details h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: capitalize;
    color: #644a3e;
  }
  .about-us-details .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .about-us-details .content .image img {
    width: 300px;
  }

  .about-us-details .content .data {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .about-us-details .content .data h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 3px;
    text-transform: capitalize;

    color: #6b5310;
  }
  .about-us-details .content .data p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    display: flex;
    align-items: center;
    letter-spacing: 3px;

    color: #1b1a1a;
  }
}
